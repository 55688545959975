import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/logo.png'; // Replace with the actual path to your logo
import { signupPartner } from '../services/api';

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  textTransform: 'none',
  backgroundColor: '#004AAD',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#003080',
  },
}));

const CorporateSignup = ({ onNext }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phoneNumber: '+61', // Default placeholder for phone number
    type: 'corporate',
  });

  const [errors, setErrors] = useState({
    email: '',
    phoneNumber: '',
  });

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const phoneRegex = /^\+\d{1,3}\d{8,14}$/;

  const handleChange = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = name === 'phoneNumber' ? value.replace(/\s+/g, '') : value;

    setFormData({
      ...formData,
      [name]: name === 'firstName' ? sanitizedValue.trim() : sanitizedValue,
    });

    if (name === 'email') {
      setErrors((prev) => ({
        ...prev,
        email: emailRegex.test(sanitizedValue) ? '' : 'Invalid email format',
      }));
    }

    if (name === 'phoneNumber') {
      const isValidPhone = phoneRegex.test(sanitizedValue) && sanitizedValue.length > 7;
      setErrors((prev) => ({
        ...prev,
        phoneNumber: isValidPhone
          ? ''
          : 'Phone number must be at least 8 digits (e.g., +6112345678)',
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      if (!formData.firstName || !formData.email || !formData.phoneNumber) {
        alert('First name, email, and phone number are mandatory.');
        return;
      }

      const sanitizedFormData = {
        ...formData,
        email: formData.email.toLowerCase(),
      };

      if (!errors.email && !errors.phoneNumber) {
        const resp = await signupPartner(sanitizedFormData);
        if (resp.status === 200) {
          onNext(sanitizedFormData);
        } else {
          alert(resp.data.message);
        }
      } else {
        alert('Please correct the errors before submitting.');
      }
    } catch (error) {
      alert(error.message);
      console.error('Submission failed:', error);
    }
  };

  return (
    <Box sx={{ width: isMobile ? '90%' : '70%', mx: 'auto', mt: isMobile ? 2 : 4 }}>
      <img src={logo} alt="GoBlu-EV Logo" height={50} width={180} style={{ marginBottom: 24 }} />
      <Typography variant="h4" fontWeight="bold" gutterBottom>
        Create your account!
      </Typography>
      <Typography variant="subtitle1" color="textSecondary" gutterBottom>
        Get unlimited discounts on your ride bookings
      </Typography>

      <Box component="form" noValidate autoComplete="off">
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="First Name"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Last Name"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Organization Name"
          name="company"
          value={formData.company}
          onChange={handleChange}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Organization Email ID"
          name="email"
          value={formData.email}
          onChange={handleChange}
          type="email"
          error={!!errors.email}
          helperText={errors.email}
        />
        <TextField
          fullWidth
          margin="normal"
          variant="outlined"
          label="Phone Number (with country code)"
          name="phoneNumber"
          placeholder="+61"
          value={formData.phoneNumber}
          onChange={handleChange}
          type="tel"
          error={!!errors.phoneNumber}
          helperText={errors.phoneNumber}
        />
        <StyledButton onClick={handleSubmit}>
          Continue
        </StyledButton>
      </Box>

      <Typography variant="body2" sx={{ marginTop: 2 }}>
        Already have an account?{' '}
        <Link href="/" underline="hover">
          Login here
        </Link>
      </Typography>
    </Box>
  );
};

export default CorporateSignup;
