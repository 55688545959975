import React, { useState, useRef } from 'react';
import { Box, TextField, Typography, Button, CircularProgress, Alert } from '@mui/material';
import { verifyOTP } from '../services/api';


export default function VerifyEmail({ onNext, email }) {
  const [otp, setOtp] = useState(['', '', '', '']);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const inputRefs = useRef([]);

  const handleChange = (index, value) => {
    if (/^\d?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (value && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace' && !otp[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleSubmit = async () => {
    if (otp.some(digit => digit === '')) {
      setError('Please fill in all fields.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const resp = await verifyOTP({ email: email, otp: parseInt(otp.join('')) }, "");
   

      if (resp.status === 200 && resp.data.success) {
        onNext();
      } else {
        setError("Invalid OTP");
      }
    } catch (error) {
      setError("An error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Box
        width="100%"
        maxWidth="400px"
        p={4}
        boxShadow={3}
        borderRadius={2}
        bgcolor="white"
      >
        <Typography variant="h5" gutterBottom textAlign="center">
          Verify Email ID
        </Typography>
        <Typography variant="body2" gutterBottom textAlign="center">
          Enter the Verification code sent to {email}
        </Typography>

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Box display="flex" justifyContent="center" mt={3} gap={2}>
          {otp.map((_, index) => (
            <TextField
              key={index}
              value={otp[index]}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleBackspace(index, e)}
              inputRef={(el) => (inputRefs.current[index] = el)}
              inputProps={{
                maxLength: 1,
                style: { textAlign: 'center', fontSize: '1.5rem' },
              }}
              sx={{
                width: '60px',
                height: '60px',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  borderWidth: 2,
                },
              }}
            />
          ))}
        </Box>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          sx={{ mt: 4, py: 1.5, fontSize: '1rem' }}
          onClick={handleSubmit}
          disabled={loading || otp.some(digit => digit === '')}
        >
          {loading ? <CircularProgress size={24} color="inherit" /> : "Continue"}
        </Button>
      </Box>
    </Box>
  );
}