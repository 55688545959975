import React, { useState } from 'react';
import {
  Box, Typography, Button, Alert, Snackbar,
  Dialog, DialogActions, DialogContent, DialogContentText,
  DialogTitle, TextField, FormHelperText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { updateUserList } from '../services/api';

function EmployeesHeader() {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [formErrors, setFormErrors] = useState({
    name: '',
    email: '',
    phoneNumber: ''
  });

  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phoneNumber: '',
  });

  const handleAddEmployeeClick = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    resetForm();
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormValues({ ...formValues, [id]: value });

    // Dynamic validation
    if (id === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setFormErrors({
        ...formErrors,
        email: emailRegex.test(value) ? '' : 'Invalid email address'
      });
    }

    if (id === 'phoneNumber') {
      const phoneRegex = /^\+\d{1,3}\d{7,14}$/; // country code + 7-14 digits
      setFormErrors({
        ...formErrors,
        phoneNumber: phoneRegex.test(value) ? '' : 'Invalid phone number with country code'
      });
    }

    if (id === 'name') {
      setFormErrors({
        ...formErrors,
        name: value ? '' : 'Name is required'
      });
    }
  };

  const handleFormSubmit = async () => {
    if (!formValues.email || !formValues.name || !formValues.phoneNumber || formErrors.email || formErrors.phoneNumber) {
      setAlertMessage('Please correct the errors in the form!');
      setAlertSeverity('warning');
      setAlertOpen(true);
      return;
    }

    try {
      const response = await updateUserList(
        { userList: [{ ...formValues ,businessEmail:formValues.email}] },
        localStorage.getItem('token')
      );

      if (response.status === 200) {
        setAlertMessage('Employee added successfully!');
        setAlertSeverity('success');
        window.location.reload()
      } else {
        setAlertMessage(response.data.message);
        setAlertSeverity('error');
      }
    } catch (error) {
      setAlertMessage('Failed to add employee. Please try again.');
      setAlertSeverity('error');
    } finally {
      setDialogOpen(false);
      setAlertOpen(true);
      resetForm();
    }
  };

  const resetForm = () => {
    setFormValues({ name: '', email: '', phoneNumber: '' });
    setFormErrors({ name: '', email: '', phoneNumber: '' });
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setAlertOpen(false);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '20px 0' }}>
      <Box>
        <Typography variant="h4" fontWeight="bold">
          Users
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Manage the ride bookings by viewing, adding, or removing your user.
        </Typography>
      </Box>
      <Button
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        sx={{
          background: 'linear-gradient(90deg, #1CB5E0 0%, #000851 100%)',
          borderRadius: '8px',
          padding: '8px 16px',
          textTransform: 'none',
        }}
        onClick={handleAddEmployeeClick}
      >
        Add User
      </Button>

      {/* Dialog for adding employees */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Add Users</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please fill in the details below to add a new user.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            value={formValues.name}
            onChange={handleInputChange}
            error={Boolean(formErrors.name)}
            helperText={formErrors.name}
          />
          <TextField
            margin="dense"
            id="email"
            label="User Email"
            type="email"
            fullWidth
            variant="standard"
            value={formValues.email}
            onChange={handleInputChange}
            error={Boolean(formErrors.email)}
            helperText={formErrors.email}
          />
          <TextField
            margin="dense"
            id="phoneNumber"
            label="Mobile No."
            type="tel"
            fullWidth
            variant="standard"
            value={formValues.phoneNumber}
            onChange={handleInputChange}
            error={Boolean(formErrors.phoneNumber)}
            helperText={formErrors.phoneNumber}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleFormSubmit} variant="contained" color="primary">
            Add User
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar to show the alert */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default EmployeesHeader;
