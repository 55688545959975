import React from 'react';
import { Box, Grid, Card, Typography, Button, Tabs, Tab } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PublicIcon from '@mui/icons-material/Public';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import EcoIcon from '@mui/icons-material/Nature';

const dummyData = {
  totalCO2Saved: 320,
  totalRides: 250,
  totalDistance: 2343,
  totalTime: 265,
  insights: [
    {
      title: "50% increase in CO2 saving",
      description: "Your CO2 savings increased from 320kg to 480kg since last week",
    },
    {
      title: "50% increase in CO2 saving",
      description: "Your CO2 savings increased from 320kg to 480kg since last week",
    },
  ],
  stats: [
    { name: '4th Aug', CO2Saved: 200 },
    { name: '7th Aug', CO2Saved: 250 },
    { name: '14th Aug', CO2Saved: 300 },
    { name: '20th Aug', CO2Saved: 800 },
    { name: '25th Aug', CO2Saved: 500 },
  ],
  companyUpdates: {
    totalRidesCompleted: 8000,
    totalCO2Saved: 10000, // in Kg
  },
};

function Tracker() {
  return (
    <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
      {/* Header Section */}
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        {/* <Typography variant="h4" fontWeight="bold">Co2 Tracker</Typography> */}
        {/* <Button variant="contained" color="primary">View Leaderboard</Button> */}
      </Box>



      {/* Overview and Insights Section */}
      <Grid container spacing={3} mb={3}>
        <Grid item xs={12} md={12}>
          <Card sx={{ padding: '20px' }}>
          <Typography variant="h6" fontWeight="bold" mb={2}>
              Your Achievements
            </Typography>
            <Typography variant="h6">Savings Overview</Typography>
            <Typography variant="h4" color="green" fontWeight="bold">
              Total {dummyData.totalCO2Saved} Kg of CO2 has been saved!
            </Typography>
            <Grid container spacing={2} mt={2}>
              <Grid item xs={4}>
                <Typography variant="body1" align="center">
                  <DirectionsCarIcon color="primary" sx={{ fontSize: 40 }} />
                  <br />
                  {dummyData.totalRides} Rides Completed
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" align="center">
                  <PublicIcon color="secondary" sx={{ fontSize: 40 }} />
                  <br />
                  {dummyData.totalDistance} Km Distance Travelled
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="body1" align="center">
                  <AccessTimeIcon color="action" sx={{ fontSize: 40 }} />
                  <br />
                  {dummyData.totalTime} hrs Time Travelled
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <Card sx={{ padding: '20px' }}>
            <Typography variant="h6">Insights</Typography>
            {dummyData.insights.map((insight, index) => (
              <Box key={index} mb={2}>
                <Typography variant="body1" color="primary">{insight.title}</Typography>
                <Typography variant="body2">{insight.description}</Typography>
              </Box>
            ))}
          </Card>
        </Grid> */}
      </Grid>

      {/* Statistics Section */}
      {/* <Card sx={{ padding: '20px' }}>
        <Typography variant="h6" mb={3}>CO2 Savings Statistics</Typography>
        <Tabs value={0} indicatorColor="primary" textColor="primary">
          <Tab label="Today" />
          <Tab label="Yesterday" />
          <Tab label="Last 7 Days" />
          <Tab label="Last 28 Days" />
          <Tab label="Custom" />
        </Tabs>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={dummyData.stats} margin={{ top: 20, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line type="monotone" dataKey="CO2Saved" stroke="#82ca9d" />
          </LineChart>
        </ResponsiveContainer>
        <Grid container spacing={2} mt={3}>
          <Grid item xs={4}>
            <Typography variant="body1" align="center">Highest Saving</Typography>
            <Typography variant="h6" align="center" fontWeight="bold">40 Kg</Typography>
            <Typography variant="body2" align="center">On 20th Aug, 2023</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" align="center">Lowest Saving</Typography>
            <Typography variant="h6" align="center" fontWeight="bold">12 Kg</Typography>
            <Typography variant="body2" align="center">On 20th Aug, 2023</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="body1" align="center">Lowest Saving</Typography>
            <Typography variant="h6" align="center" fontWeight="bold">12 Kg</Typography>
            <Typography variant="body2" align="center">in 21 days</Typography>
          </Grid>
        </Grid>
      </Card> */}
    </Box>
  );
}

export default Tracker;