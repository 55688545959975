import React, { useState } from 'react';
import {
  Box,
  Button,
  TextField,
  Typography,
  Link,
  Divider,
  CircularProgress,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { styled } from '@mui/system';
import logo from '../assets/images/logo.png';
import { partnerAuth, forgotPassword } from '../services/api';


const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  flex: 1.5,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  [theme.breakpoints.down('md')]: {
    flex: 1,
    padding: theme.spacing(3),
  },
}));

const SideImage = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: '#004AAD',
  backgroundImage: `url('./auth.svg')`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  padding: theme.spacing(1.5),
  width: '100%',
  textTransform: 'none',
}));

export default function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordMessage, setForgotPasswordMessage] = useState('');
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    setErrorMessage('');

    try {
      const response = await partnerAuth({ username: email.toLowerCase(), password });

      const { user, tokens } = response.data;

      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', tokens.access.token);

      console.log('Login successful:', user);
  

      if (user && tokens.access) {
        window.location.reload();
      } else {
        setErrorMessage('Invalid Credentials');
      }
    } catch (error) {
      setErrorMessage('Invalid Credentials');
      console.error('Login failed:', error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    setForgotPasswordLoading(true);
    setForgotPasswordMessage('');

    try {
      const response = await forgotPassword({ email: forgotPasswordEmail.toLowerCase() });
    
    if(response.status === 200){
      setForgotPasswordMessage('Credentials have been sent to your email ID.');
      console.log('Forgot Password successful:', response.data);
    }else{
      setForgotPasswordMessage(response?.data?.message || 'Failed to send credentials.');
 
    }
    } catch (error) {
      setForgotPasswordMessage(error.response?.data?.message || 'Failed to send credentials.');
      console.error('Forgot Password failed:', error.response?.data || error.message);
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  const handleForgotPasswordClick = () => {
    setOpenForgotPasswordModal(true);
  };

  const handleCloseForgotPasswordModal = () => {
    setOpenForgotPasswordModal(false);
    setForgotPasswordEmail('');
    setForgotPasswordMessage('');
  };

  return (
    <Container>
      <FormWrapper>
        <Box sx={{ width: { xs: '100%', sm: '80%', md: '70%' } }}>
          <img src={logo} alt="GoBlu-EV Logo" height={50} width={150} style={{ marginBottom: 24 }} />
          <Typography variant="h4" gutterBottom fontWeight="bold" textAlign="center">
            Log into your account!
          </Typography>

          <Divider sx={{ width: '100%', marginY: 2 }}></Divider>

          {errorMessage && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {errorMessage}
            </Alert>
          )}

          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={loading}
          />
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            margin="normal"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading}
          />

          <StyledButton
            variant="contained"
            color="primary"
            onClick={handleLogin}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} sx={{ color: '#fff' }} /> : 'Continue'}
          </StyledButton>

          <Link
            href="#"
            variant="body2"
            sx={{ marginTop: 2, display: 'block', textAlign: 'center' }}
            onClick={handleForgotPasswordClick}
          >
            Forgot Password?
          </Link>

          <Link href="/sign-up" variant="body2" sx={{ marginTop: 2, display: 'block', textAlign: 'center' }}>
            Don't have an account? Create new account
          </Link>
        </Box>
      </FormWrapper>

      <SideImage />

      {/* Forgot Password Modal */}
      <Dialog open={openForgotPasswordModal} onClose={handleCloseForgotPasswordModal}>
        <DialogTitle>Forgot Password</DialogTitle>
        <DialogContent>
          <TextField
            label="Enter your email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={forgotPasswordEmail}
            onChange={(e) => setForgotPasswordEmail(e.target.value)}
            disabled={forgotPasswordLoading}
          />
          {forgotPasswordMessage && (
            <Alert severity={forgotPasswordMessage.includes('sent') ? 'success' : 'error'}>
              {forgotPasswordMessage}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseForgotPasswordModal} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleForgotPassword}
            color="primary"
            disabled={forgotPasswordLoading || !forgotPasswordEmail}
          >
            {forgotPasswordLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}