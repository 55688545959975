import React, { useEffect, useState } from 'react';
import {
  Paper, Table, TableBody, TableCell, TableContainer, TableHead,
  TablePagination, TableRow, IconButton, Menu, MenuItem, CircularProgress,
  Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, TextField
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { getUserList, removeUserList, updateUserList } from '../services/api';

const columns = [
  { id: 'fullName', label: 'Name', minWidth: 170 },
  { id: 'businessEmail', label: 'Email', minWidth: 170, align: 'right' },
  { id: 'employeeID', label: 'User ID', minWidth: 170, align: 'right' },
  { id: 'phoneNumber', label: 'Phone Number', minWidth: 170, align: 'right' },
  { id: 'actions', label: 'Actions', minWidth: 50, align: 'right' },
];

export default function StickyHeadTable() {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogAction, setDialogAction] = useState('');
  const [editValues, setEditValues] = useState({ name: '', email: '', phoneNumber: '' }); // State for edit form

  const fetchUserList = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await getUserList("", localStorage.getItem('token'));
      const data = response.data.map((item) => ({
        ...item,
        employeeID: item.id, // Assuming _id as employeeID
      }));
      setRows(data);
    } catch (error) {
      setError('Failed to fetch user data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleRemove = () => {
    setDialogAction('remove');
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleEdit = () => {
    setEditValues({ name: selectedRow.fullName, businessEmail: selectedRow.email, phoneNumber: selectedRow.phoneNumber });
    setDialogAction('edit');
    setOpenDialog(true);
    handleMenuClose();
  };

  const handleEditChange = (e) => {
    const { id, value } = e.target;
    setEditValues({ ...editValues, [id]: value });
  };

  const confirmAction = async () => {
    if (selectedRow) {
      if (dialogAction === 'remove') {
        await removeUserList(selectedRow, localStorage.getItem('token'));
      } else if (dialogAction === 'edit') {
        const updatedUser = {
          id: selectedRow.employeeID,
          name: editValues.name,
          email: editValues.email,
          phoneNumber: editValues.phoneNumber,
        };
        await updateUserList({userList:[updatedUser]}, localStorage.getItem('token'));
      }
      setOpenDialog(false);
      fetchUserList(); // Re-fetch the user list after action
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      {loading ? (
        <CircularProgress sx={{ margin: '20px auto', display: 'block' }} />
      ) : error ? (
        <Typography sx={{ margin: '20px auto', display: 'block', textAlign: 'center' }} color="error">
          {error}
        </Typography>
      ) : rows.length === 0 ? (
        <Typography sx={{ margin: '20px auto', display: 'block', textAlign: 'center' }}>
          No users found.
        </Typography>
      ) : (
        <>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.employeeID}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === 'actions' ? (
                              <>
                                <IconButton
                                  aria-label="more"
                                  aria-controls="long-menu"
                                  aria-haspopup="true"
                                  onClick={(event) => handleMenuClick(event, row)}
                                >
                                  <MoreVertIcon />
                                </IconButton>
                              </>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edit</MenuItem>
        <MenuItem onClick={handleRemove}>Remove</MenuItem>
      </Menu>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>{dialogAction === 'remove' ? 'Remove User' : 'Edit User'}</DialogTitle>
        <DialogContent>
          {dialogAction === 'remove' ? (
            <DialogContentText>
              Are you sure you want to remove {selectedRow?.fullName}?
            </DialogContentText>
          ) : (
            <>
              <TextField
                margin="dense"
                id="name"
                label="Full Name"
                type="text"
                fullWidth
                value={editValues.name}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="email"
                fullWidth
                value={editValues.email}
                onChange={handleEditChange}
              />
              <TextField
                margin="dense"
                id="phoneNumber"
                label="Phone Number"
                type="tel"
                fullWidth
                value={editValues.phoneNumber}
                onChange={handleEditChange}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={confirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
}
