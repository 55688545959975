import axios from "axios";
axios.defaults.baseURL = "https://prod.goblu-ev.com/v1";
// axios.defaults.baseURL = "http://localhost:8900/v1";
export const commonrequest = async (methods, url, body, header) => {
  let config = {
    method: methods,
    url,
    headers: header
      ? header
      : {
          "Content-Type": "application/json",
        },
    data: body,
  };


  return axios(config)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error.response;
    });
};

export const getUser = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/partner/profile`, data, header);
};

export const partnerAuth = async (data, header) => {
  return await commonrequest(
    "POST",
    `/partner/partner-auth`,
    data,
    header
  );
};

// export const verifyOTP = async (data) => {
//   let header = {
//     "Access-Control-Allow-Origin": "*",
//     "Access-Control-Allow-Credentials": "true",
//     "Access-Control-Allow-Headers": "content-type",
//     "Access-Control-Allow-Methods": "GET",
//   };
//   return await commonrequest("POST", `/auth/user/phone-verify`, data, header);
// };

export const updateUserList = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/add-user-list`, data, header);
};


export const removeUserList = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/remove-user`, data, header);
};


export const getUserList = async (data, token) => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    return await commonrequest("GET", `/partner/get-user-list`, "", header);
  };
  

  export const estimatePrice = async (data,token) => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    return await commonrequest("POST", `/partner/partner-estimate-fare`, data,header);
  };
  
  export const slotdates = async (data, token) => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    return await commonrequest("GET", `/rides/slotdates`, data, header);
  };
  
  export const slotAvailability = async (data, param, requestId, token) => {
    let header = {
      Authorization: `Bearer ${token}`,
    };
    return await commonrequest(
      "GET",
      `/partner/get-available-slots?date=${param}&serviceid=${requestId}`,
      data,
      header
    );
  };
// export const updateRequest = async (param, token) => {
//   let header = {
//     Authorization: `Bearer ${token}`,
//   };
//   return await commonrequest(
//     "GET",
//     `/rides/updateRequest/${param}`,
//     "",
//     header
//   );
// };

// export const serviceStatus = async (data) => {
//   return await commonrequest("POST", `/zones/web/service-availability`, data);
// };

// export const estimatePrice = async (data) => {
//   return await commonrequest("POST", `/rides/web/estimate-fare`, data);
// };

// export const slotdates = async (data, token) => {
//   let header = {
//     Authorization: `Bearer ${token}`,
//   };
//   return await commonrequest("GET", `/rides/slotdates`, data, header);
// };

// export const slotAvailability = async (data, param, requestId, token) => {
//   let header = {
//     Authorization: `Bearer ${token}`,
//   };
//   return await commonrequest(
//     "GET",
//     `/rides/web/viewAvailableSlots?date=${param}&serviceid=${requestId}`,
//     data,
//     header
//   );
// };

// export const discountedPrice = async (data, requestId) => {
//   return await commonrequest(
//     "GET",
//     `/rides/${requestId}/discountedPrice`,
//     data
//   );
// };

export const confirmBooking = async (data, token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/partner-schedule-ride`, data, header);
};

export const cancelBooking = async (data, token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/partner-cancel-ride`, data, header);
};

export const pastBookingsApi = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  console.log(header)
  return await commonrequest("GET", `/partner/total-bookings-partner`, "", header);
};

export const addPickupNote = async (body, token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/rides/${id}/pickup-note`, body, header);
};



export const fetchSessionUrl = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/payment/create-checkout-session`,
    "",
    header
  );
};

export const fetchPayment = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/payment/details`, "", header);
};

export const getPublishableKey = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/payment/get-publishable-key`, "", header);
};

export const removePayment = async (token, id) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest(
    "GET",
    `/payment/remove-card?id=${id}`,
    "",
    header
  );
};

export const bookingStats = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/booking-stats`, {} ,header);
};



export const fetchPartnerInvoices = async (data,token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/completed-rides-invoice`, data ,header);
};


export const profile = async (token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("GET", `/partner/profile`, {} ,header);
};


export const updateProfile = async (data,token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("PATCH", `/partner/profile`, data ,header);
};

export const signupPartner = async (data,token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/traveller/partner-signup`, data ,header);
};


export const verifyOTP = async (data,token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/traveller/partner-otp-verify`, data ,header);
};


export const forgotPassword = async (data,token) => {
  let header = {
    Authorization: `Bearer ${token}`,
  };
  return await commonrequest("POST", `/partner/forgot-password`, data ,header);
};

